<template>
  <div class="partner-card" @click="isPermission = false">
    <div class="partner-details">
      <div class="partner-image">
        <div v-if="partner.accept_invite == 1" class="name-avatar">
          {{ abbrevateName(partner.name).toUpperCase() }}
        </div>
        <v-avatar
          v-if="partner.accept_invite == 0"
          :size="64"
          color="grey lighten-4"
          class="profile_bg"
          :style="{ backgroundImage: `url(${imageAvatar})` }"
        ></v-avatar>
      </div>
      <div class="partner-information">
        <div class="partner-name">
          {{ partner.accept_invite == 1 ? partner.name : "Not registered" }}
        </div>
        <div class="partner-role">
          {{
            partner.permission == "Project Manager" ? "PM" : partner.permission
          }}
        </div>
        <div class="parter-email-address">{{ partner.email }}</div>
      </div>
    </div>
    <div class="parner-actions">
      <div
        class="collaborator-role"
        @click="handleChangePermission($event, partner.id)"
      >
        {{ partner.permission }}
      </div>
      <div class="remove-collaborator" @click="showRemoveDialog(partner)">
        Remove
      </div>
    </div>
    <div v-if="isPermission && partner.id == currentId" class="permission-list">
      <div
        class="list-item-div"
        v-for="(role, index) in collaboratorRoles"
        :key="index"
        @click="updateCollaboratorRole(partner.id, role.name)"
      >
        {{ role.name }}
      </div>
    </div>
  </div>
</template>

<script>
import shortName from "@/mixins/shortName";
export default {
  mixins: [shortName],
  props: {
    partner: {
      required: true,
      type: Object,
    },
    showRemoveDialog: {
      type: Function,
      required: true,
    },
    collaboratorRoles: {
      type: Array,
      required: true,
    },
    updateCollaboratorRole: {
      type: Function,
      required: true,
    },
  },
  components: {},

  data: () => ({
    imageAvatar: "/img/onboarding/profile-avatar.svg",
    isPermission: false,
    currentId: "",
  }),

  created() {},
  methods: {
    handleChangePermission(e, id) {
      e.stopPropagation();
      this.isPermission = true;
      this.currentId = id;
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.partner-card {
  width: 381px;
  /* width: 32%; */
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  margin-right: 2%;
  margin-bottom: 16px;
  position: relative;
}
.partner-details {
  display: flex;
}
.name-avatar {
  background: #54bdd4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
}
.partner-information {
  margin-left: 16px;
}
.partner-role,
.partner-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.partner-role {
  color: #979da5;
  margin-top: 4px;
}
.parter-email-address {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  margin-top: 4px;
}
.collaborator-role {
  background: #f7f7f8;
  border-radius: 12px;
  font-size: 10px;
  line-height: 12px;
  color: #1b1e22;
  padding: 6px 12px;
}
.remove-collaborator {
  font-size: 12px;
  line-height: 140%;
  color: #d45b54;
  margin-top: 21px;
  text-align: right;
  cursor: pointer;
}
.permission-list {
  position: absolute;
  background: #ffffff;
  border: 1px solid #f7f7f8;
  box-sizing: border-box;
  box-shadow: 5px 7px 30px rgba(21, 23, 26, 0.05);
  border-radius: 4px;
  text-align: left;
  right: 24px;
  top: 52px;
  z-index: 100;
}
.list-item-div {
  padding: 12px 16px;
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  cursor: pointer;
}
.list-item-div:hover {
  background: #f7f7f8;
}
.collaborators-container .partner-card:nth-child(3n + 3) {
  margin-right: 0px;
}
</style>
