var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "partner-card",
      on: {
        click: function ($event) {
          _vm.isPermission = false
        },
      },
    },
    [
      _c("div", { staticClass: "partner-details" }, [
        _c(
          "div",
          { staticClass: "partner-image" },
          [
            _vm.partner.accept_invite == 1
              ? _c("div", { staticClass: "name-avatar" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.abbrevateName(_vm.partner.name).toUpperCase()
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm.partner.accept_invite == 0
              ? _c("v-avatar", {
                  staticClass: "profile_bg",
                  style: { backgroundImage: "url(" + _vm.imageAvatar + ")" },
                  attrs: { size: 64, color: "grey lighten-4" },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "partner-information" }, [
          _c("div", { staticClass: "partner-name" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.partner.accept_invite == 1
                    ? _vm.partner.name
                    : "Not registered"
                ) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "partner-role" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.partner.permission == "Project Manager"
                    ? "PM"
                    : _vm.partner.permission
                ) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "parter-email-address" }, [
            _vm._v(_vm._s(_vm.partner.email)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "parner-actions" }, [
        _c(
          "div",
          {
            staticClass: "collaborator-role",
            on: {
              click: function ($event) {
                return _vm.handleChangePermission($event, _vm.partner.id)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.partner.permission) + "\n    ")]
        ),
        _c(
          "div",
          {
            staticClass: "remove-collaborator",
            on: {
              click: function ($event) {
                return _vm.showRemoveDialog(_vm.partner)
              },
            },
          },
          [_vm._v("\n      Remove\n    ")]
        ),
      ]),
      _vm.isPermission && _vm.partner.id == _vm.currentId
        ? _c(
            "div",
            { staticClass: "permission-list" },
            _vm._l(_vm.collaboratorRoles, function (role, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "list-item-div",
                  on: {
                    click: function ($event) {
                      return _vm.updateCollaboratorRole(
                        _vm.partner.id,
                        role.name
                      )
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(role.name) + "\n    ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }