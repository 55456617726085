import { render, staticRenderFns } from "./addCollaboratorModal.vue?vue&type=template&id=1597f0c8&scoped=true&"
import script from "./addCollaboratorModal.vue?vue&type=script&lang=js&"
export * from "./addCollaboratorModal.vue?vue&type=script&lang=js&"
import style0 from "./addCollaboratorModal.vue?vue&type=style&index=0&id=1597f0c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1597f0c8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1597f0c8')) {
      api.createRecord('1597f0c8', component.options)
    } else {
      api.reload('1597f0c8', component.options)
    }
    module.hot.accept("./addCollaboratorModal.vue?vue&type=template&id=1597f0c8&scoped=true&", function () {
      api.rerender('1597f0c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ClientDashboard/collaborators/addCollaboratorModal.vue"
export default component.exports