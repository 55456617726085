<template>
  <div>
    <Toolbar stageTitle="Collaborators">
      <button
        class="grupa-blue-btn"
        @click="isAddModal = true"
        style="margin-top: 9px"
      >
        Invite
      </button>
    </Toolbar>
    <Loader v-if="projectDetails == null" />
    <div v-else class="page-container">
      <div class="div-wrapper">
        <div class="container-title">Invite Collaborators</div>
        <div class="page-description">
          Invite key stakeholders in your team to collaborate on this product.
          Invite co-founders, CTOs so that everyone is on the same page
        </div>
        <div class="collaborators-container">
          <ParnerCard
            v-for="(partner, index) in collaborators"
            :key="index"
            v-bind="{
              partner,
              showRemoveDialog,
              collaboratorRoles,
              updateCollaboratorRole,
            }"
          />
        </div>
      </div>
    </div>
    <AddCollaborator
      v-if="isAddModal"
      v-bind="{
        closeDialog,
        spinner,
        form,
        handleInviteCollaborator,
        selectPermissions,
        collaboratorRoles,
      }"
    />
    <RemoveCollaborator
      v-if="isRemoveModal"
      v-bind="{ collaborator, closeDialog, spinner, handleRemoveACollaborator }"
    />
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  inviteCollaborator,
  editCollaboratorRole,
  deleteCollaborator,
  projectDocuments,
} from "@/api";
import Loader from "@/components/general/centerLoader";
import Toolbar from "@/components/toolbar/generalToolBar";
import ParnerCard from "./partnerCard";
import AddCollaborator from "./addCollaboratorModal";
import RemoveCollaborator from "./removeCollaborator";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
export default {
  props: {},
  components: {
    Loader,
    Toolbar,
    ParnerCard,
    AddCollaborator,
    RemoveCollaborator,
    AlertSuccess,
    AlertError,
  },

  data: () => ({
    projectDetails: null,
    collaborators: [],
    isAddModal: false,
    spinner: false,
    isRemoveModal: false,
    collaborator: {},
    form: {
      email: "",
      permission: "Investor",
    },
    collaboratorRoles: [
      { name: "Investor", value: "Investor" },
      { name: "Partner", value: "Partner" },
      { name: "Manager", value: "Manager" },
      { name: "Collaborator", value: "Collaborator" },
    ],
  }),

  created() {
    const projectId = this.$route.params.id;
    this.getCollaborators(projectId);
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    getCollaborators(id) {
      projectDocuments(id).then((response) => {
        this.projectDetails = response.data.project;
        this.collaborators = this.projectDetails.collaborators;
      });
    },
    selectPermissions(permission) {
      this.form.permission = permission;
    },
    closeDialog() {
      this.isAddModal = false;
      this.isRemoveModal = false;
    },
    showRemoveDialog(payload) {
      this.collaborator = payload;
      this.isRemoveModal = true;
    },
    handleInviteCollaborator() {
      let payload = this.form;
      this.spinner = true;
      payload.project_id = this.$route.params.id;
      inviteCollaborator(payload)
        .then((response) => {
          this.spinner = false;
          if (response.data.status == 1) {
            this.collaborators = response.data.data;
            this.isAddModal = false;
            this.setSuccessMsg(
              "Successfully! An email has been sent to your collaborator"
            );
          } else {
            this.setErrorMsg("An error occured, please try again");
          }
        })
        .catch((err) => {
          this.spinner = false;
          this.setErrorMsg("An error occured, please try again");
        });
    },
    handleRemoveACollaborator() {
      this.spinner = true;
      let { id } = this.collaborator;
      deleteCollaborator(id)
        .then((response) => {
          if (response.data.status == 1) {
            let partners = this.filterCollaborator(id);
            this.collaborators = partners;
            this.spinner = false;
            this.setSuccessMsg("Collaborator Removed");
            this.isRemoveModal = false;
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.setErrorMsg("Please try again");
        });
    },
    updateCollaboratorRole(collaborator_id, permission) {
      let payload = {
        collaborator_id,
        permission,
      };
      editCollaboratorRole(payload)
        .then((resp) => {
          this.collaborators = resp.data.collaborators;
          this.setSuccessMsg("Permission changed");
        })
        .catch((err) => {
          this.$developConsole(err.response, "Api Error");
        });
    },
    filterCollaborator(id) {
      let collaborators = JSON.parse(JSON.stringify(this.collaborators));
      return collaborators.filter((partner) => partner.id != id);
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.div-wrapper {
  text-align: left;
}
.page-description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
  width: 477px;
}
.collaborators-container {
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
}
</style>
