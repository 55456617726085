var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function ($event) {
          _vm.selectRole = false
        },
      },
    },
    [
      _c("div", { staticClass: "modal-wrap" }, [
        _c("div", { staticClass: "dialog-header" }, [
          _c("div", { staticClass: "dialog-title" }, [
            _vm._v("Invite Collaborators"),
          ]),
          _c(
            "div",
            { staticClass: "close-btn", on: { click: _vm.closeDialog } },
            [
              _c("img", {
                attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "dialog-content" }, [
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "invite-instruction" }, [
              _vm._v(
                "\n          Enter email address, and select the product role of your\n          colleague(s).\n        "
              ),
            ]),
            _vm._m(0),
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
            _c("div", { staticClass: "form-container" }, [
              _c("div", { staticClass: "input-container" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "email-text-box",
                  attrs: { placeholder: "enter email address", type: "email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "select-role-div",
                    on: {
                      click: function ($event) {
                        return _vm.showPermissions($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.form.permission) +
                        "\n              "
                    ),
                    _c("img", {
                      staticStyle: { "margin-left": "8px" },
                      attrs: {
                        src: _vm.selectRole
                          ? "/img/arrow-down.png"
                          : "/img/arrow-up.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _vm.selectRole
                  ? _c(
                      "div",
                      { staticClass: "permission-list" },
                      _vm._l(_vm.collaboratorRoles, function (role, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "list-item-div",
                            on: {
                              click: function ($event) {
                                return _vm.selectPermissions(role.name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(role.name) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "dialog-footer" }, [
          _c(
            "button",
            { staticClass: "grupa-white-btn", on: { click: _vm.closeDialog } },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "grupa-blue-btn",
              staticStyle: { "margin-left": "8px" },
              on: { click: _vm.handleInviteCollaborator },
            },
            [
              _vm._v("\n        Send Invite\n        "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.spinner,
                    expression: "spinner",
                  },
                ],
                staticClass: "button-spinner",
                staticStyle: { "margin-left": "20px" },
                attrs: { src: "/img/lightbox/preloader.gif" },
              }),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "role-instruction" }, [
      _c("div", { staticClass: "bullet" }, [_vm._v(".")]),
      _c("div", [
        _c("span", [_vm._v("Investor")]),
        _vm._v(
          "\n            (e.g. Angels, Accelerators) has full access to the product.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "role-instruction" }, [
      _c("div", { staticClass: "bullet" }, [_vm._v(".")]),
      _c("div", [
        _c("span", [_vm._v("Partner")]),
        _vm._v(
          " (e.g. Cofounders) has full access to the\n            product.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "role-instruction" }, [
      _c("div", { staticClass: "bullet" }, [_vm._v(".")]),
      _c("div", [
        _c("span", [_vm._v(" Manager ")]),
        _vm._v(
          "\n            Manager (e.g. CFO, Project Coordinator, PM) is able to do every\n            interfacing (Can Mark Sprints as done etc.) needed with Product\n            Team, but does not have access to manage payments.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "role-instruction" }, [
      _c("div", { staticClass: "bullet" }, [_vm._v(".")]),
      _c("div", [
        _c("span", [_vm._v("Collaborator")]),
        _vm._v(
          "is able to only view progress of product\n            development, s/he cannot make any decisions on the Platform.\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }