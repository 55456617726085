var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Toolbar", { attrs: { stageTitle: "Collaborators" } }, [
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            staticStyle: { "margin-top": "9px" },
            on: {
              click: function ($event) {
                _vm.isAddModal = true
              },
            },
          },
          [_vm._v("\n      Invite\n    ")]
        ),
      ]),
      _vm.projectDetails == null
        ? _c("Loader")
        : _c("div", { staticClass: "page-container" }, [
            _c("div", { staticClass: "div-wrapper" }, [
              _c("div", { staticClass: "container-title" }, [
                _vm._v("Invite Collaborators"),
              ]),
              _c("div", { staticClass: "page-description" }, [
                _vm._v(
                  "\n        Invite key stakeholders in your team to collaborate on this product.\n        Invite co-founders, CTOs so that everyone is on the same page\n      "
                ),
              ]),
              _c(
                "div",
                { staticClass: "collaborators-container" },
                _vm._l(_vm.collaborators, function (partner, index) {
                  return _c(
                    "ParnerCard",
                    _vm._b(
                      { key: index },
                      "ParnerCard",
                      {
                        partner: partner,
                        showRemoveDialog: _vm.showRemoveDialog,
                        collaboratorRoles: _vm.collaboratorRoles,
                        updateCollaboratorRole: _vm.updateCollaboratorRole,
                      },
                      false
                    )
                  )
                }),
                1
              ),
            ]),
          ]),
      _vm.isAddModal
        ? _c(
            "AddCollaborator",
            _vm._b(
              {},
              "AddCollaborator",
              {
                closeDialog: _vm.closeDialog,
                spinner: _vm.spinner,
                form: _vm.form,
                handleInviteCollaborator: _vm.handleInviteCollaborator,
                selectPermissions: _vm.selectPermissions,
                collaboratorRoles: _vm.collaboratorRoles,
              },
              false
            )
          )
        : _vm._e(),
      _vm.isRemoveModal
        ? _c(
            "RemoveCollaborator",
            _vm._b(
              {},
              "RemoveCollaborator",
              {
                collaborator: _vm.collaborator,
                closeDialog: _vm.closeDialog,
                spinner: _vm.spinner,
                handleRemoveACollaborator: _vm.handleRemoveACollaborator,
              },
              false
            )
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }