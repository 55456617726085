<template>
  <div class="modal-container" @click="selectRole = false">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">Invite Collaborators</div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="content-wrapper">
          <div class="invite-instruction">
            Enter email address, and select the product role of your
            colleague(s).
          </div>
          <div class="role-instruction">
            <div class="bullet">.</div>
            <div>
              <span>Investor</span>
              (e.g. Angels, Accelerators) has full access to the product.
            </div>
          </div>
          <div class="role-instruction">
            <div class="bullet">.</div>
            <div>
              <span>Partner</span> (e.g. Cofounders) has full access to the
              product.
            </div>
          </div>
          <div class="role-instruction">
            <div class="bullet">.</div>
            <div>
              <span> Manager </span>
              Manager (e.g. CFO, Project Coordinator, PM) is able to do every
              interfacing (Can Mark Sprints as done etc.) needed with Product
              Team, but does not have access to manage payments.
            </div>
          </div>
          <div class="role-instruction">
            <div class="bullet">.</div>
            <div>
              <span>Collaborator</span>is able to only view progress of product
              development, s/he cannot make any decisions on the Platform.
            </div>
          </div>
          <div class="form-container">
            <div class="input-container">
              <input
                placeholder="enter email address"
                v-model="form.email"
                class="email-text-box"
                type="email"
              />
              <div class="select-role-div" @click="showPermissions($event)">
                {{ form.permission }}
                <img
                  :src="
                    selectRole ? `/img/arrow-down.png` : `/img/arrow-up.png`
                  "
                  style="margin-left: 8px"
                  alt=""
                />
              </div>
              <div class="permission-list" v-if="selectRole">
                <div
                  class="list-item-div"
                  v-for="(role, index) in collaboratorRoles"
                  :key="index"
                  @click="selectPermissions(role.name)"
                >
                  {{ role.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
        <button
          @click="handleInviteCollaborator"
          style="margin-left: 8px"
          class="grupa-blue-btn"
        >
          Send Invite
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    form: {
      required: true,
      type: Object,
    },
    handleInviteCollaborator: {
      type: Function,
      required: true,
    },
    selectPermissions: {
      type: Function,
      required: true,
    },
    collaboratorRoles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectRole: false,
    };
  },
  created() {},
  methods: {
    showPermissions(e) {
      e.stopPropagation();
      this.selectRole = true;
    },
  },
  computed: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
.small-modal {
  width: 404px;
}
.content-wrapper {
  width: 356px;
  text-align: left;
}
.invite-instruction {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.role-instruction {
  font-size: 12px;
  line-height: 130%;
  color: #53585f;
  margin-top: 12px;
  display: flex;
}
.bullet,
.role-instruction span {
  font-weight: bold;
}
.bullet {
  margin-right: 3px;
  margin-top: -3px;
}
.input-container {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
}
.email-text-box {
  margin-top: 6px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.email-text-box:focus {
  outline: none;
}
.select-role-div {
  background: #f7f7f8;
  border-radius: 20px;
  padding: 6px 16px;
  color: #1b1e22;
  font-size: 12px;
  line-height: 140%;
  cursor: pointer;
}
.permission-list {
  position: absolute;
  background: #ffffff;
  border: 1px solid #f7f7f8;
  box-sizing: border-box;
  box-shadow: 5px 7px 30px rgba(21, 23, 26, 0.05);
  border-radius: 4px;
  text-align: left;
  right: 16px;
  top: 44px;
  z-index: 100;
}
.list-item-div {
  padding: 12px 16px;
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  cursor: pointer;
}
.list-item-div:hover {
  background: #f7f7f8;
}
</style>


